import styles from "./profile.module.css";
import { Button } from "antd";
import { Edit, Lock } from "react-feather";
import avatar from "assets/icons/avatar.png";
import useAuth from "../../../hooks/useAuth";
import { useState } from "react";
import EditProfileModal from "components/EditProfileModal/EditProfileModal";
import ChangePassModal from "components/ChangePassModal/ChangePassModal";
import Title from "antd/lib/typography/Title";

export default function ProfileView(props: ReachPathProps) {
  const { user, dsp } = useAuth();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isPassOpen, setIsPassOpen] = useState(false);

  return (
    <>
      <div className={styles.main}>
        <div className={styles.heading}>
          <Button
            type="link"
            icon={<Lock height={14} />}
            onClick={() => {
              setIsPassOpen(true);
            }}
          >
            Change password
          </Button>
          <Button
            type="link"
            icon={<Edit height={14} />}
            onClick={() => {
              setIsEditOpen(true);
            }}
          >
            Edit profile
          </Button>
        </div>
        <div className={styles["profile-card"]}>
          <div className={styles.circle} />
          <div className={styles.circle} />
          <img
            src={dsp?.businessLogo || avatar}
            className={styles.avatar}
            height={250}
            width={250}
            alt="Profile"
          />
          <div className={styles["profile-content"]}>
            <div className={styles.name}>
              <Title
                style={{ textTransform: "uppercase", margin: 0 }}
                level={3}
              >{`${user!.firstName} ${user!.lastName}`}</Title>
            </div>
            <div className={styles.email}>
              <td>{user!.email}</td>
            </div>
            <div className={styles.phoneNumber}>
              <td>{user!.phoneNumber}</td>
            </div>
          </div>
        </div>
      </div>
      <EditProfileModal
        isEditOpen={isEditOpen}
        onCancel={() => {
          setIsEditOpen(false);
        }}
      />
      <ChangePassModal
        isPassOpen={isPassOpen}
        onCancel={() => {
          setIsPassOpen(false);
        }}
      />
    </>
  );
}
