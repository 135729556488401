import { Modal, Form, Input, Button, Row, Col, Select, message } from "antd";
import axiosConfig from "config/axios";
import { useEffect, useState } from "react";

type Props = {
  showModal: boolean;
  onCancel: () => void;
  afterClose: () => void;
  id?: string;
  truck?: truck[];
};

const { Option } = Select;
export default function EditTruck({
  showModal,
  onCancel,
  afterClose,
  id,
  truck,
}: Props) {
  const [form] = Form.useForm();
  const [trucks, setTrucks] = useState<[]>([]);
  const [tonnage, setTonnage] = useState<[]>([]);

  useEffect(() => {
    axiosConfig
      .get("truck")
      .then(({ data: { data } }) => {
        setTrucks(data);
      })
      .catch((error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit(values: any) {
    try {
      await axiosConfig.put(`/truckmangement/edit/${id}`, {
        ...values,
      });
      onCancel();
      message.success("Truck Edited successfully");
    } catch (error) {
      message.error("Error: editing truck" + error);
    }
  }

  const SelectedTruck = (selectedTruckType: any) => {
    trucks.map(
      (truck: any) =>
        truck.truckType === selectedTruckType && setTonnage(truck.truckTonnage)
    );
  };

  return (
    <Modal
      footer={null}
      visible={showModal}
      title={"Edit truck"}
      onCancel={onCancel}
      afterClose={afterClose}
    >
      <Form
        initialValues={truck}
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Row justify="space-around">
          <Col>
            <Form.Item
              rules={[
                { required: true, message: "Truck name cannot be blank" },
              ]}
              name="truckType"
              label="Truck Type"
            >
              <Select onChange={SelectedTruck}>
                {trucks &&
                  trucks.map((truck: any) => (
                    <Option key={truck.id} value={truck.truckType}>
                      {truck.truckType}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Brand cannot be blank" }]}
              name="brand"
              label="Brand"
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Year cannot be blank" }]}
              name="year"
              label="Year"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Registration number cannot be blank",
                },
              ]}
              name="regNumber"
              label="Registration number"
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Model cannot be blank" }]}
              name="model"
              label="Model"
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Tonnage cannot be blank" }]}
              name="tonnage"
              label="Tonnage"
            >
              <Select>
                {tonnage &&
                  tonnage.map((tonne: any) => (
                    <Option key={tonne} value={tonne}>
                      {tonne}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Button htmlType="submit" block type="primary" size="large">
          Save
        </Button>
      </Form>
    </Modal>
  );
}
