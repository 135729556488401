import { Table, Input, message, Button, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./request.module.css";
import classNames from "classnames";
import TopTab from "components/TopTab/TopTab";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import Text from "antd/lib/typography/Text";
import { navigate } from "@reach/router";
import useSearch from "hooks/useSearch";
import { getMonth } from "./recent";

const columns = [
  {
    title: "DATE",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text: string) => <span>{dayjs(text).format("DD MM YYYY")}</span>,
  },
  {
    title: "REFERENCE",
    dataIndex: "frontendId",
    key: "frontendId",
  },
  {
    title: "AMOUNT",
    dataIndex: "deliveryPrice",
    key: "deliveryPrice",
    render: (text: number) => <span>₦{text?.toLocaleString()}</span>,
  },
  {
    title: "STATUS",
    dataIndex: "isPaid",
    key: "isPaid",
    render: (text: boolean) =>
      text ? (
        <Text type="success"> Paid </Text>
      ) : (
        <Text type="danger"> Unpaid </Text>
      ),
  },
];

export default function History({ id, path }: { id?: string; path: string }) {
  const [page] = useState(0);
  const { dsp } = useAuth();
  const [, setTotalpages] = useState(1);
  const [data, setData] = useState<PaymentsPartner[] | any>([]);
  const [loading, setLoading] = useState<boolean>();
  const { handleSearch, isSearching, searchQuery } = useSearch();

  let searchResult = data?.filter((d: any) => {
    return (
      d.id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      d.deliveryPrice.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      d.isPaid.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      dayjs(d.createdAt)
        .format("DD MM YYYY")
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1
    );
  });
  const getPayments = useCallback(() => {
    setLoading(true);
    axios
      .get(`paymentpartner/getallunpaidbylogistics/${dsp?.id}`, {
        params: { page },
      })
      .then(({ data: { data } }) => {
        for (const [keys, values] of Object.entries(data.groupedEntries)) {
          keys.split("/")[0] === id && setData(values);
        }
        setTotalpages(data.totalpages);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Error fetching data");
        setLoading(false);
      });
  }, [page, dsp?.id, id]);
  useEffect(() => {
    getPayments();
  }, [getPayments]);
  return (
    <>
      <Row>
        <Col>
          <Button
            onClick={() => navigate("/dsp/payments")}
            type="text"
            style={{ padding: 0 }}
          >
            Payments
          </Button>{" "}
          <small style={{ opacity: 0.5 }}>| {getMonth(id)}</small>
        </Col>
      </Row>
      <TopTab
        links={[
          { to: "", name: "Pending payments" },
          { to: `/dsp/payments/history/${id}`, name: "History" },
        ]}
      />
      <div className={classNames(["c-end", styles.search])}>
        <div>
          <Input
            onChange={(e) => handleSearch(e)}
            placeholder="search"
            prefix={<SearchOutlined />}
          />
        </div>
      </div>
      <div style={{ overflowX: "auto" }}>
        <Table
          style={{ minWidth: "600px" }}
          rowClassName={styles["table-row"]}
          dataSource={isSearching ? searchResult : data}
          onRow={(row) => {
            return {
              onClick: (event) => {
                navigate(`./payments/${row.id}`);
              },
            };
          }}
          columns={columns}
          loading={loading}
        />
      </div>
    </>
  );
}
