import { message } from "antd";

const ErrorHandler = (
  error: any,
  defaultMessage: string = "An error occured"
) => {
  if (error.response) {
    const data = error.response.data;
    if (data.error) message.error(data.error);
    data.errors?.forEach((error: any) => message.error(error.msg));
  } else message.error(defaultMessage);
};

export default ErrorHandler;
