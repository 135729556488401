import { Spin } from "antd";

export default function PageSpinner({ page }: { page?: boolean }) {
  return (
    <div
      style={{
        width: "100%",
        height: `${page ? "50vh" : "100%"}`,
        display: "grid",
        placeItems: "center",
      }}
    >
      <Spin />
    </div>
  );
}
