import { Modal, Form, Input, Button, message, Spin } from "antd";
import axiosConfig from "config/axios";
// import useAuth from "hooks/useAuth";
import { useState } from "react";

type Props = {
  showModal: boolean;
  onCancel: () => void;
  afterClose: () => void;
  dspOrderId?: string;
  transactionId?: string;
  setDspOrder?: (e: any) => void;
  callback?: () => void;
};

export default function DriverInfo({
  showModal,
  onCancel,
  afterClose,
  dspOrderId,
  transactionId,
  setDspOrder,
  callback,
}: Props) {
  const [form] = Form.useForm();
  // const { dsp } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  async function handleSubmit(values: any) {
    setIsSubmitting(true);
    await axiosConfig
      .post("logistics/approveorder", {
        ...values,
      })
      .then(async () => {
        onCancel();
        setIsSubmitting(false);
        message.success("Order Approved!");
        callback && callback();
      })
      .catch(function (error) {
        message.error("An error occured:", error);
        setIsSubmitting(false);
      });
  }
  return (
    <Modal
      footer={null}
      visible={showModal}
      title={"Driver Information"}
      onCancel={onCancel}
      afterClose={afterClose}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          rules={[{ required: true, message: "Please enter Dirver's name " }]}
          name="driverName"
          label="Driver Name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
            {
              pattern: /^(\+?234?)?0?(7|8|9)(0|1)\d{8}$/,
              message: "Please provide a valid phone number.",
            },
          ]}
          name="drivePhone"
          label="Driver Phone Number"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Color cannot be blank" }]}
          name="logisticsPartnerId"
          initialValue={dspOrderId}
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Color cannot be blank" }]}
          name="transactionId"
          initialValue={transactionId}
          hidden
        >
          <Input />
        </Form.Item>
        <Button
          disabled={isSubmitting}
          htmlType="submit"
          block
          type="primary"
          size="large"
        >
          {isSubmitting ? <Spin /> : "PROCEED"}
        </Button>
      </Form>
    </Modal>
  );
}
