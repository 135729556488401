import { Modal, Form, Input, Button, message } from "antd";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import { useState } from "react";
type Props = {
  isPassOpen: boolean;
  onCancel: () => void;
};
export default function ChangePassModal({ isPassOpen, onCancel }: Props) {
  const { logOut } = useAuth();
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  async function handleSubmit() {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      await axios.put("user/password", form.getFieldsValue());
      onCancel();
      form.resetFields();
      message.success("Password changed successfully");
      setTimeout(() => {
        message.success("Please login with new password");
        logOut();
      }, 1000);
    } catch (error) {
      message.error("Error changoing password " + error);
    } finally {
      setIsSubmitting(false);
    }
  }
  return (
    <Modal
      footer={null}
      visible={isPassOpen}
      title={"Change Password"}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          rules={[{ required: true, message: "Cannot be blank" }]}
          name="currentPassword"
          label="Current Password"
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Cannot be blank" }]}
          name="newPassword"
          label="New Password"
          hasFeedback
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item
          // rules={[{ required: true, message: "Cannot be blank" }]}
          name="confirmNewPassword"
          label="Confirm New Password"
          dependencies={["newPassword"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" block type="primary" size="large">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
