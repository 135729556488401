import {
  Input,
  Button,
  Table,
  Menu,
  Dropdown,
  Modal,
  Form,
  Checkbox,
  Row,
  Col,
  message,
} from "antd";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import { useCallback, useEffect, useState } from "react";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import styles from "./profile.module.css";
import classNames from "classnames";
import AddUser from "components/AddUser/AddUser";
import useSearch from "hooks/useSearch";

export default function UserManagement(props: ReachPathProps) {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [editModal, setEditModal] = useState<User | null>(null);
  const { handleSearch, isSearching, searchQuery } = useSearch();

  let searchResult = users?.filter((user: User) => {
    return (
      user.firstName.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      user.lastName.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  });
  const {
    user: { organizationId },
  } = useAuth() as { user: User };
  const getUsers = useCallback(
    async function getUsers() {
      setLoading(true);
      try {
        const {
          data: {
            data: { organizationUsers },
          },
        } = await axios.get(`organization/${organizationId}/users`);
        setUsers(organizationUsers);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [organizationId]
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const menu = (item: User) => (
    <Menu
      onClick={function handleMenuClick(e) {
        if (e.key === "remove")
          Modal.confirm({
            title: `Are you sure you want to remove ${item.firstName} ${item.lastName}?`,
            okText: "Yes",
            onOk: async function handleRemove() {
              await axios.post("user/removeuserfromorg", {
                userId: item.id,
                organizationId,
              });
              getUsers();
            },
          });
        else if (e.key === "edit") setEditModal(item);
      }}
    >
      <Menu.Item key="remove">Remove from Organization</Menu.Item>
      <Menu.Item key="edit">Edit permissions</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "EMAiL ADDRESS",
      dataIndex: "email",
      key: "email",
      render: (text: string) => (
        <span style={{ textTransform: "lowercase" }}>{text}</span>
      ),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "USER ROLE",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (a: void, total: User) =>
        total.role !== "admin" && (
          <Dropdown overlay={menu(total)}>
            <Button icon={<MoreOutlined />} type="primary"></Button>
          </Dropdown>
        ),
    },
  ];

  return (
    <div className={styles.main}>
      <div className={classNames(["c-end", styles.search])}>
        <div>
          <Input
            onChange={(e) => handleSearch(e)}
            placeholder="search"
            prefix={<SearchOutlined />}
          />
        </div>
        {/* <Button onClick={() => setIsModal(true)} type="primary">
          Add User
        </Button> */}
      </div>
      <div className={styles["table-container"]}>
        <Table
          size="small"
          rowClassName={styles["table-row"]}
          dataSource={isSearching ? searchResult : users}
          columns={columns}
          loading={loading}
          style={{ textTransform: "capitalize" }}
        />
      </div>
      <AddUser isModalOpen={isModal} onCancel={() => setIsModal(false)} />
      <Modal
        visible={!!editModal}
        footer={null}
        onCancel={() => {
          setEditModal(null);
        }}
        destroyOnClose
        title={`Edit ${editModal?.firstName} ${editModal?.lastName}'s permissions`}
      >
        <Form
          onFinish={async (e) => {
            try {
              await axios.post("user/addpermission", e);
              setEditModal(null);
            } catch (error) {
              message.error("Something went wrong");
            }
          }}
          layout="vertical"
          preserve={false}
        >
          <Form.Item name="userId" hidden initialValue={editModal?.id}>
            <Input />
          </Form.Item>
          <Form.Item
            initialValue={editModal?.permissions}
            rules={[{ required: true, message: "Select at least one role" }]}
            name="permissions"
            label="Edit permissions"
          >
            <Checkbox.Group className="w-100">
              <Row gutter={[10, 10]}>
                <Col>
                  <Checkbox value="todo">Todo</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" block type="primary" size="large">
              Continue
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
