import { User, FileText, CreditCard, Truck } from "react-feather";
import GetQuotation from "pages/dsp/delivery-orders";
import Profile from "pages/dsp/profile";
import Payments from "pages/dsp/payments";
import DashBoard from "pages/dsp/dashboard";
import TruckManagement from "pages/dsp/truck-management";

type DashboardRoute = {
  path: `${string}/*`;
  title: string;
  component: (props: ReachPathProps) => JSX.Element;
  icon: React.ReactNode;
};

const dashboardRoute: DashboardRoute[] = [
  {
    path: "dashboard/*",
    component: DashBoard,
    icon: <FileText />,
    title: "Dashboard",
  },
  {
    path: "delivery/*",
    component: GetQuotation,
    icon: <FileText />,
    title: "Delivery Orders",
  },
  {
    path: "truck-management/*",
    component: TruckManagement,
    icon: <Truck />,
    title: "Truck Management",
  },
  {
    path: "payments/*",
    component: Payments,
    icon: <CreditCard />,
    title: "Payment",
  },
  { path: "profile/*", component: Profile, icon: <User />, title: "Profile" },
];

export default dashboardRoute;
