import { Router } from "@reach/router";
import DashboardShared from "components/DashboardShared/DashboardShared";
import History from "./history";
import PaymentView from "./old-recent";
import Recent from "./recent";

// import PaymentDetails from "./payment-details";

export default function Payments(props: ReachPathProps) {
  return (
    <DashboardShared title="Payments">
      <Router>
        <Recent path="/" />
        <PaymentView path="/:id" />
        <History path="history/:id" />
        {/* <PaymentDetails path=":id" /> */}
      </Router>
    </DashboardShared>
  );
}
