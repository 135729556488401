import { configureStore } from "@reduxjs/toolkit";
import auth from "./slices/auth";
import sidenav from "./slices/sidenav";
import cart from "./slices/cart";
import tempcart from "./slices/tempcart";

export const store = configureStore({
  reducer: { auth, sidenav, cart, tempcart },
  devTools: process.env.NODE_ENV !== "production",
});

// Infer the `RootState` type from the store itself
export type RootState = ReturnType<typeof store.getState>;
