import {
  Typography,
  Button,
  Space,
  message,
  Skeleton,
  Divider,
  Popconfirm,
  Alert,
  Spin,
} from "antd";
import { CheckSquareOutlined, FileTextOutlined } from "@ant-design/icons";
// import DashboardShared from "components/DashboardShared/DashboardShared";
import styles from "./transactions.module.css";
import { useCallback, useEffect, useRef, useState } from "react";
import axios from "config/axios";
import dayjs from "dayjs";
import { navigate } from "@reach/router";
// import Countdown from "react-countdown";
import axiosConfig from "config/axios";
import useAuth from "hooks/useAuth";
import DriverInfo from "components/DriverInfo";
import Modal from "antd/lib/modal/Modal";
import ReactToPrint from "react-to-print";

const { Text, Title } = Typography;

const TransactionDetail = (props: ReachPathProps & { id?: string }) => {
  const { dsp } = useAuth();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [timeElapsed, setTimeElapsed] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [dspOrder, setDspOrder] = useState<any>();
  const [orderRejected, setOrderRejected] = useState<boolean>(false);
  const [showWaybillModal, setShowWaybillModal] = useState<boolean>();

  let componentRef = useRef<HTMLDivElement>(null);

  // const [isAccepted, setIsAccepted] = useState();

  // const [orderInfo, setOrderInfo] = useState<any | null>(null);

  // const [showModal, setShowModal] =  useState<boolean>(false)

  const getTransactionInfo = useCallback(async () => {
    // setLoading(true);
    try {
      const {
        data: { transaction },
      } = await axios.get(`transaction/gettransactionbytransid/${props.id}`);
      setTransaction(transaction);
      getOrderInfo();
    } catch {
      message.error("Error fetching order info");
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const transactionId = props.id;
  const logisticsPartnerId = transaction?.logisticsPartnerId;

  const getOrderInfo = () => {
    axiosConfig
      .get(`logistics/dsporder/${transactionId}`)
      .then(({ data: { data } }) => {
        setDspOrder(data);
        data?.accepted === false && setOrderRejected(true);
      })
      .catch((error) => {});
  };

  const AcceptOrder = () => {
    setShowModal(true);
  };
  const RejectOrder = () => {
    axiosConfig
      .post("logistics/rejectorder", { transactionId, logisticsPartnerId })
      .then(({ data: { data } }) => {
        message.success("Order declined!");
        setTimeElapsed(true);
        if (data.accepted === false) setOrderRejected(true);
        getTransactionInfo();
      })
      .catch((error) => {
        if (error === "No DSP found") {
          message.error(
            "Sorry this order cannot be rejected at this time",
            error
          );
        } else {
          message.error(error);
        }
      });
  };

  const trackingHandler = async ({
    url,
    success,
    error,
  }: {
    url: string;
    success: string;
    error: string;
  }) => {
    setLoading(true);
    try {
      await axios.post(url, {
        transactionId,
      });
      getTransactionInfo();
      message.success(success);
    } catch {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactionInfo();

    // const rejected = localStorage.get("rejected")
    // if (rejected === "true") setTimeElapsed(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!transaction && !dspOrder) {
    return (
      <div className={` ${styles["details-card-container"]}`}>
        <Skeleton.Input
          style={{
            marginTop: "3rem",
            width: "400px",
            height: "270px",
            borderRadius: "10px",
          }}
          active
        />
        <Skeleton.Input
          style={{
            marginTop: "3rem",
            width: "400px",
            height: "270px",
            borderRadius: "10px",
          }}
          active
        />

        <Skeleton.Input
          style={{
            marginTop: "1rem",
            width: "800px",
            height: "200px",
            borderRadius: "10px",
          }}
          active
        />
      </div>
    );
  }
  // const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  //   if (completed) {

  //     setTimeElapsed(true);
  //     return <>YEAH</>;
  //   }
  //   // Render a countdown
  //   return (
  //     !timeElapsed && (
  //       <Title type={minutes === 0 && seconds < 15 ? "danger" : "success"}>
  //         <span className="time">{days}:</span>
  //         <span className="time">{hours}:</span>
  //         <span className="time">{minutes}:</span>
  //         <span className="time">{seconds}</span>
  //       </Title>
  //     )
  //   );
  // };
  const pickUp =
    transaction?.trackingStatus === "order_created" ||
    transaction?.trackingStatus === "order_processed";

  const notAccepted = dspOrder === undefined || !orderRejected;
  // const timeCreated = dayjs();

  // if (dayjs(transaction?.createdAt) !== dayjs(new Date())) {
  //   console.log("processedAt", dayjs(transaction?.processedAt));
  //   console.log("createdAt", dayjs(transaction?.createdAt));
  //   console.log("now", timeCreated);

  //   console.log(
  //     "Time diff",
  //     timeCreated.diff(dayjs(transaction?.processedAt), "second")
  //   );
  // }
  // const timeCreated = dayjs("2022-04-05");

  return (
    transaction && (
      <div className={` ${styles["details-container"]}`}>
        <div className={`${styles["details-topbar"]}`}>
          <div>
            <Button
              type="text"
              style={{ padding: 0 }}
              onClick={() => navigate("/dsp/delivery")}
            >
              Orders
            </Button>
            <span style={{ opacity: ".5" }}>
              {" "}
              | {transaction.order.frontendId}
            </span>
          </div>

          {dspOrder?.accepted ? (
            <></>
          ) : (
            notAccepted && (
              <div className={styles.timer}>
                {!timeElapsed ? (
                  <> </>
                ) : (
                  // <Title
                  //   style={{ textAlign: "center", margin: 0, padding: 0 }}
                  //   level={5}
                  // >
                  //   Time left to accept order{" "}
                  //   <Countdown
                  //     date={
                  //       Date.now() +
                  //       7200000 -
                  //       timeCreated.diff(
                  //         dayjs(transaction?.processedAt),
                  //         "second"
                  //       )
                  //     }
                  //     zeroPadTime={2}
                  //     renderer={renderer}
                  //   />
                  //   {/* <Title level={4} type="danger">ORDER TIME ELLAPSED</Title> */}
                  //   {/* </Countdown>{" "} */}
                  //   {}
                  // </Title>
                  <Title
                    level={3}
                    style={{ textAlign: "center" }}
                    type="danger"
                  >
                    ORDER TIME
                  </Title>
                )}
              </div>
            )
          )}
          <Space style={{ overflowX: "auto" }}>
            {dspOrder?.accepted ? (
              <></>
            ) : (
              notAccepted && (
                <>
                  <Button
                    type="primary"
                    disabled={timeElapsed}
                    onClick={() => AcceptOrder()}
                  >
                    ACCEPT
                  </Button>
                  <Popconfirm
                    overlayInnerStyle={{ minWidth: "300px" }}
                    title="Are you sure you want to decline this order?"
                    onConfirm={() => RejectOrder()}
                    cancelButtonProps={{
                      size: "middle",
                      type: "primary",
                    }}
                    okButtonProps={{
                      size: "middle",
                      type: "default",
                      danger: true,
                    }}
                    okText="YES"
                    cancelText="CANCEL"
                  >
                    <Button type="primary" disabled={timeElapsed} danger>
                      DECLINE
                    </Button>
                  </Popconfirm>
                </>
              )
            )}
            {orderRejected && (
              <Alert
                style={{
                  boxShadow: "0px 5px 10px #00000022",
                  textAlign: "center",
                  lineHeight: 1.1,
                  fontSize: "1.5rem",
                  display: "flex",
                  flexDirection: "column",
                }}
                showIcon
                type="error"
                message={<span> You rejected this order</span>}
              />
            )}
            {dspOrder?.accepted && (
              <Button onClick={() => setShowWaybillModal(true)} type="primary">
                Waybill
              </Button>
            )}
            {pickUp && dspOrder?.accepted && (
              <Button
                icon={<CheckSquareOutlined />}
                type="link"
                disabled={loading}
                onClick={() =>
                  trackingHandler({
                    url: "logistics/changetrackingstatuspickup",
                    success: "Order picked up",
                    error: "Error processing order",
                  })
                }
              >
                {loading ? (
                  <>
                    {" "}
                    Mark as Picked <Spin size="small" />{" "}
                  </>
                ) : (
                  "Mark as Picked"
                )}
              </Button>
            )}
            {dspOrder?.accepted &&
              transaction.trackingStatus === "driver_pickup" && (
                <Button
                  onClick={() =>
                    trackingHandler({
                      url: "logistics/changetrackingstatusenroute",
                      success: "Order Enroute",
                      error: "Error processing order",
                    })
                  }
                  icon={<FileTextOutlined />}
                  type="link"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      {" "}
                      Mark as Enroute <Spin size="small" />{" "}
                    </>
                  ) : (
                    "Mark as Enroute"
                  )}
                </Button>
              )}
            {dspOrder?.accepted && transaction.trackingStatus === "enroute" && (
              <Button
                onClick={() =>
                  trackingHandler({
                    url: "logistics/changetrackingstatusdelivered",
                    success: "Order Delivered",
                    error: "Error processing order",
                  })
                }
                icon={<FileTextOutlined />}
                type="link"
                disabled={loading}
              >
                {loading ? (
                  <>
                    {" "}
                    Mark as Delivered <Spin size="small" />{" "}
                  </>
                ) : (
                  "Mark as Delivered"
                )}
              </Button>
            )}
            {dspOrder?.accepted &&
              transaction.trackingStatus === "delivered" && (
                <>
                  <h1>DELIVERED</h1>
                  <Alert message="Order delivered" type="success" />
                </>
              )}
          </Space>
          <Modal
            footer={
              <div>
                <ReactToPrint
                  trigger={() => <Button>Print aybill</Button>}
                  content={() => componentRef.current}
                />
              </div>
            }
            width="70%"
            visible={showWaybillModal}
            onCancel={() => setShowWaybillModal(false)}
          >
            <div ref={componentRef} className={styles["waybill-container"]}>
              <div className={styles["top-address"]}>
                <span className={styles.left}>
                  {transaction.order.billingAddress}
                  <br />
                  {transaction.order.billingCity}
                  <br />
                  {transaction.order.billingState} State
                  <br />
                  Nigeria
                  <br />
                  Waybill Number: {transaction.frontendId}
                  <br />
                  Invoice Number: {transaction.order.frontendId}
                </span>
                <span className={styles.right}>
                  {dsp?.businessAddress} <br />
                  {dsp?.state}
                </span>
              </div>
              <table>
                <thead>
                  <th> S/N</th>
                  <th> Item description</th>
                  <th> Quantity</th>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      {transaction.variant.material.brand.brandName}{" "}
                      {transaction.variant.material.brand.brandName} -{" "}
                      <small style={{ opacity: 0.5 }}>
                        {transaction.variant.description}
                      </small>
                    </td>
                    <td>{transaction.quantity}</td>
                  </tr>
                </tbody>
              </table>
              <table style={{ marginTop: "1rem" }}>
                <tr>
                  <h3>Delivery comment</h3>
                </tr>
                <tr className={styles["delivery-comment"]}>
                  <td>{transaction.order.billingNote}</td>
                </tr>
              </table>
              <table>
                <tr>
                  <td>
                    <h3> Received by:</h3>
                  </td>
                  <td>{dsp?.businessName}</td>
                </tr>
                <tr>
                  <td>
                    <h3> Signature:</h3>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <h3> Date:</h3>
                  </td>
                  <td></td>
                </tr>
              </table>
            </div>
          </Modal>
          <DriverInfo
            showModal={showModal}
            onCancel={() => setShowModal(false)}
            afterClose={() => {
              getTransactionInfo();
            }}
            callback={() => setShowWaybillModal(true)}
            transactionId={transactionId}
            dspOrderId={transaction.logisticsPartnerId}
          />
          {/* <Modal
          className={styles.modal}
          title="Track order"
          onCancel={() => setShowModal(false)}
          visible={showModal}
          footer={false}
        >
          <Timeline>
            <Timeline.Item color="green">Order created</Timeline.Item>
            <Timeline.Item color="green">Order processed</Timeline.Item>
            <Timeline.Item color="green">
              Driver at pickup location
            </Timeline.Item>
            <Timeline.Item color="yellow">Enroute</Timeline.Item>
            <Timeline.Item color="gray">Delivered</Timeline.Item>
          </Timeline>
        </Modal> */}
        </div>
        <div className={timeElapsed || orderRejected ? styles["time-up"] : ""}>
          <div className={`${styles["details-card-container"]}`}>
            <div className={styles["details-card"]}>
              <span>
                Invoice ID
                <b>{transaction.order.frontendId}</b>
              </span>
              <span>
                Order date
                <b>{dayjs(transaction.createdAt).format("DD MMM YYYY")}</b>
              </span>
              <span>
                Builder
                <b>{transaction.builder.businessName}</b>
              </span>
              <span>
                Truck type
                <b>{transaction.variant.material.category?.truck.truckType}</b>
              </span>
              <span>
                Number of trips
                <b>{transaction.numOfTrips}</b>
              </span>
              <span>
                Quantity
                <b>
                  {transaction.quantity}{" "}
                  {transaction.variant.material.unitOfMeasure}
                </b>
              </span>
              <span>
                Payment status
                {transaction.order.isPaidFor ? (
                  <b style={{ color: "#00962C" }}>Paid</b>
                ) : (
                  <b style={{ color: "#FF612F" }}>Unpaid</b>
                )}
              </span>
            </div>
            <div className={styles["details-card"]}>
              <span>
                Delivery status
                {transaction.trackingStatus === "delivered" ? (
                  <b style={{ color: "#00962C" }}>Delivered</b>
                ) : (
                  <b style={{ color: "#F2994A" }}>Pending</b>
                )}
              </span>
              <span>
                <Text>Delivery option</Text>
                <b>Deliver to address</b>
              </span>
              <span>
                <Text>Delivery address</Text>
                <b>{transaction.deliveryAddress}</b>
              </span>
              {/* <span>
            <Text>State</Text>
            <b>Lagos, Nigeria</b>
          </span> */}
              <span>
                <Text>Supplier</Text>
                <b>{transaction.supplier.businessName}</b>
              </span>
              <span>
                <Text>Pickup address</Text>
                <b>{transaction.supplier.businessAddress}</b>
              </span>
              {transaction.deliveryPartner ? (
                <span>
                  <Text>Delivery partner</Text>
                  <b>Fastex delivery solution</b>
                </span>
              ) : (
                ""
              )}
              <span>
                <Text>Expected delivery date</Text>
                <b>{dayjs(transaction.deliveryDate).format("DD MM YYYY")}</b>
              </span>
            </div>
          </div>
          <div className={styles["main-body"]}>
            <div className={styles["profile-card"]}>
              <div className={styles.circle} />
              <div className={styles.circle} />
              <img
                src={transaction.variant.material.imageUrl}
                alt={`${transaction.variant.material.brand?.brandName} ${transaction.variant.material.category?.name} ${transaction.variant.description}`}
                className={styles.avatar}
                height={200}
              />
              <div className={styles["profile-content"]}>
                <div className={styles.name}>
                  <Typography.Title
                    style={{
                      textTransform: "capitalize",
                      padding: 0,
                      margin: 0,
                    }}
                    level={3}
                  >{`${transaction.variant.material.brand.brandName} ${transaction.variant.material.category?.name} `}</Typography.Title>

                  <Divider />
                  <Typography.Title
                    style={{
                      textTransform: "uppercase",
                      opacity: 0.6,
                      padding: 0,
                      margin: 0,
                    }}
                    level={4}
                  >{` ${transaction.variant.description}`}</Typography.Title>
                </div>
                <div className={styles.email}>
                  <p></p>
                </div>
                <div className={styles.phoneNumber}>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default TransactionDetail;
