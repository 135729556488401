import { Modal, Form, Input, Button, message } from "antd";
import SearchPlace from "components/SearchPlace";
import axiosConfig from "config/axios";
import useAuth from "hooks/useAuth";

type Props = {
  isEditOpen: boolean;
  onCancel: () => void;
};

export default function EditBusinessInfo({ isEditOpen, onCancel }: Props) {
  const { user, dsp, logIn } = useAuth();
  const [form] = Form.useForm();

  async function handleSubmit(values: any) {
    try {
      const {
        data: { data },
      } = await axiosConfig.put("/logistics/editprofile", {
        dspId: dsp!.id,
        ...values,
      });
      onCancel();
      message.success("Business info updated");
      logIn(user!, data);
    } catch (error) {
      message.error("Error updating Business info");
    }
  }
  return (
    <Modal footer={null} visible={isEditOpen} title={"Edit Business Info"} onCancel={onCancel}>
      <Form form={form} layout="vertical" onFinish={handleSubmit} initialValues={dsp!}>
        <Form.Item
          rules={[{ required: true, message: "Business Name cannot be blank" }]}
          name="businessName"
          label="Business Name">
          <Input />
        </Form.Item>
        <SearchPlace
          rules={[{ required: true, message: "Business address cannot be blank" }]}
          name="businessAddress"
          label="Business address"
          placeName="businessAddressPlaceId"
          form={form}
        />
        <Form.Item rules={[{ required: true, message: "state cannot be blank" }]} name="state" label="state">
          <Input />
        </Form.Item>
        <Form.Item rules={[{ required: true, message: "zip code cannot be blank" }]} name="zipCode" label="Zip code">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" block type="primary" size="large">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
