import styles from "../../pages/dsp/layout.module.css";
import logo from "assets/logo/logo.png";
// import avatar from "../../assets/icons/avatar.png";
import { Menu, Button } from "antd";
import config from "../../config/dashboardRoute";
import { Link, navigate, useMatch } from "@reach/router";
import { useCallback, useMemo } from "react";
import { XCircle, LogOut } from "react-feather";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toggle, close } from "../../redux/slices/sidenav";
import useAuth from "../../hooks/useAuth";

export default function SideNav() {
  const { logOut, dsp } = useAuth();
  const { open } = useSelector((state: RootState) => state.sidenav);
  const dispatch = useDispatch();
  const params = useMatch("*") as {
    "*": string;
    uri: string;
    path: `${string}/*`;
  };
  const path = useMemo(() => {
    return params?.["*"].replace(/\/.*/, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.["*"]]);
  const getBasePath = useCallback((path: `${string}/*`) => {
    return path.replace(/\/\*$/, "");
  }, []);
  return (
    <div
      className={classNames([
        styles.side__nav,
        { [styles.side__nav__close]: !open },
      ])}
    >
      <div>
        <div className={styles.cancel}>
          <a href="https://materialpro-home.netlify.app/">
            <img src={logo} alt="logo" className={styles.logo} />
          </a>

          <button
            onClick={() => {
              dispatch(toggle());
            }}
          >
            <XCircle />
          </button>
        </div>
        <div
          onClick={() => {
            dispatch(close());
            navigate("/dsp/profile");
          }}
          className={styles.dp}
        >
          <div className={styles.circle} />
          <div>
            <img
              src={
                dsp?.businessLogo ||
                "https://materialspro-media.s3.us-west-1.amazonaws.com/user-avatar.png"
              }
              width={100}
              alt="profile"
            />
          </div>
          <p>{dsp?.businessName}</p>
          <span>DSP Admin</span>
        </div>
        <Menu
          className={styles.menu}
          onSelect={() => {
            dispatch(close());
          }}
          selectedKeys={[path]}
          mode="inline"
        >
          {config.map(({ icon, title, path }) => {
            return (
              <Menu.Item
                className={styles.menuItem}
                key={getBasePath(path)}
                icon={icon}
                style={
                  window.location.pathname.includes(getBasePath(path))
                    ? {
                        backgroundColor: "transparent",
                        borderLeft: "3px solid #00962C",
                        opacity: 1,
                      }
                    : {
                        backgroundColor: "transparent",
                        borderLeft: "3px solid transparent",
                        opacity: 0.4,
                      }
                }
              >
                <Link onClick={() => dispatch(toggle())} to={getBasePath(path)}>
                  {title}
                </Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
      <div>
        <Button
          onClick={logOut}
          type="link"
          block
          danger
          icon={<LogOut height={14} />}
          style={{ paddingLeft: 0, textAlign: "start" }}
        >
          <span style={{ marginLeft: 10 }}>Log Out</span>
        </Button>
      </div>
    </div>
  );
}
