import { Tooltip as ToolTip, Typography } from "antd";
import DashboardShared from "components/DashboardShared/DashboardShared";
import styles from "./dashboard.module.css";
import DashBoardCard from "./DashboardCard";
// import ArrowDown from "assets/icons/ArrowDown";
import Bar from "./Bar";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import icon_1 from "assets/p-orders.png";
import icon_2 from "assets/p-deliveries.png";
// import icon_3 from "assets/s-payout.png";
import icon_4 from "assets/dsp-payout.png";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import randomColors from "randomcolor";
import axiosConfig from "config/axios";
const { Title } = Typography;
// const { Option } = Select;

export default function DashBoard(props: ReachPathProps) {
  const [data, setData] = useState<any>();
  ChartJS.register(ArcElement, Tooltip, Legend);

  const { user, dsp } = useAuth() as { user: User; dsp: DSP };
  const topSellers = [
    { date: "Jan", name: "Cement", qty: 2000 },
    { date: "Fed", name: "Steel rod", qty: 600 },
    { date: "Mar", name: "Aluminium", qty: 1700 },
    { date: "Apr", name: "Stariway rod", qty: 500 },
    { date: "May", name: "Concrete mix", qty: 1300 },
  ];
  const pieData = {
    label: topSellers.map((top) => top.name),
    data: topSellers.map((top) => top.qty),
  };
  const colors: string[] = [];
  topSellers.map((top) =>
    colors.push(randomColors({ format: "rgba", alpha: 0.5 }))
  );
  // const colors = randomColors({ count: 10 });
  const dataSet = {
    labels: pieData.label,
    datasets: [
      {
        label: "# of Votes",
        data: pieData.data,
        backgroundColor: [
          "rgba(80, 59, 214, 0.5)",
          "rgba(98, 229, 183, 0.5)",
          "rgba(239, 136, 183, 0.5)",
          "rgba(214, 252, 75, 0.5)",
          "#00962C33",
        ],
        borderWidth: 1,
        innerWidth: 200,
      },
    ],
  };
  useEffect(() => {
    axiosConfig
      .get(`dashboard/dsp/${dsp.id}`)
      .then((res) => setData(res.data.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardShared title="DSP Dashboard">
      <div className={styles.container}>
        <div>
          <Title
            level={5}
            style={{ textTransform: "capitalize", color: "#92C064" }}
          >
            {" "}
            👋 Dear {user.lastName}{" "}
          </Title>
        </div>
        <div className={styles["cards-container"]}>
          <DashBoardCard
            statistics={data?.pendingOrder}
            title="Pending orders"
            color="#00962C"
            route={`#`}
            icon={icon_1}
          />
          <DashBoardCard
            statistics={data?.fufilledOrder}
            title="Fulfilled orders"
            color="#00962C"
            route={`#`}
            icon={icon_2}
          />
          {/* <DashBoardCard
            statistics={30000000.0}
            title="Open Quotations"
            color="#00962C"
            payout
            route={`#`}
            icon={icon_3}
          /> */}
          <DashBoardCard
            statistics={data?.pendingReciveables}
            title="Pending recievables"
            color="#00962C"
            payout
            route={`#`}
            icon={icon_4}
          />
        </div>
        {/* <div className={styles.filter}>
          <Select
            defaultValue="30"
            bordered={false}
            suffixIcon={<ArrowDown accent size="14" />}
            size="large"
          >
            <Option value="30">Month to date</Option>
            <Option value="60">Year to date</Option>
            <Option value="90">
              <Button
                type="text"
                onClick={() => setShowDatePicker(true)}
                style={{ padding: 0 }}
              >
                Pick a date
              </Button>
            </Option>
          </Select>
          {showDatePicker && (
            <DatePicker
              onSelect={() => setShowDatePicker(false)}
              open={showDatePicker}
            />
          )}
        </div> */}
        <div className={styles["graph-container"]}>
          <div className={styles.graph}>
            {/* <div className={styles["graph-title"]}>
              <Title level={4}>Top Selling Products</Title>
            </div>
            <div className={styles["graph-bars"]}>
              {topSellers
                .sort((a, b) => b.qty - a.qty)
                .map((top) => {
                  return (
                    <>
                      <Tooltip title={top.name}>
                        <Bar
                          date={top.date}
                          title={top.name}
                          minimum={top.qty}
                        />
                      </Tooltip>
                    </>
                  );
                })}
            </div> */}
            <Doughnut data={dataSet} />
          </div>
          <div className={styles.graph}>
            <div className={styles["graph-title"]}>
              <Title level={4}>Total sales value</Title>
            </div>
            <div className={styles["graph-bars"]}>
              {topSellers
                .sort((a, b) => b.qty - a.qty)
                .splice(0, 6)
                .map((top, idx) => {
                  return (
                    <>
                      {/* <div className={styles["graph-marks"]} /> */}
                      <ToolTip key={idx} title={top.name}>
                        <Bar
                          date={(idx + 1).toString()}
                          title={top.date}
                          minimum={top.qty}
                        />
                      </ToolTip>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </DashboardShared>
  );
}
