import { Table, Input, message, Row, Col, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./request.module.css";
import classNames from "classnames";
import TopTab from "components/TopTab/TopTab";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import { navigate } from "@reach/router";
import { getMonth } from "./recent";

const columns = [
  {
    title: "DATE",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text: string) => <span>{dayjs(text).format("DD MM YYYY")}</span>,
  },
  {
    title: "REFERENCE",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "AMOUNT",
    dataIndex: "deliveryPrice",
    key: "deliveryPrice",
    render: (text: number) => <span>₦{text?.toLocaleString()}</span>,
  },
  {
    title: "STATUS  ",
    dataIndex: "isPaid",
    key: "isPaid",
    render: (text: boolean) => (text ? "Paid" : "Unpaid"),
  },
];

export default function History({ id, path }: { id?: string; path: string }) {
  const [page] = useState(0);
  const { dsp } = useAuth();
  const [, setTotalpages] = useState(1);
  const [data, setData] = useState<PaymentsPartner[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const getPayments = useCallback(() => {
    setLoading(true);
    axios
      .get(`paymentpartner/getallpaidbylogistics/${dsp?.id}`, {
        params: { page },
      })
      .then(({ data: { data } }) => {
        setData(data.paymentPartners);
        setTotalpages(data.totalpages);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Error fetching data");
        setLoading(false);
      });
  }, [page, dsp?.id]);
  useEffect(() => {
    getPayments();
  }, [getPayments]);
  return (
    <>
      <Row>
        <Col>
          <Button
            onClick={() => navigate("/dsp/payments")}
            type="text"
            style={{ padding: 0 }}
          >
            Payments
          </Button>{" "}
          <small style={{ opacity: 0.5 }}>| {getMonth(id)}</small>
        </Col>
      </Row>
      <TopTab
        links={[
          { to: `/dsp/payments/${id}`, name: "Pending payments" },
          { to: "", name: "History" },
        ]}
      />
      <div className={classNames(["c-end", styles.search])}>
        <div>
          <Input placeholder="search" prefix={<SearchOutlined />} />
        </div>
      </div>
      <div style={{ overflowX: "auto" }}>
        <Table
          loading={loading}
          style={{ minWidth: "600px" }}
          rowClassName={styles["table-row"]}
          dataSource={data}
          columns={columns}
        />
      </div>
    </>
  );
}
