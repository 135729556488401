import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  message,
  InputNumber,
} from "antd";
import axiosConfig from "config/axios";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";

type Props = {
  showModal: boolean;
  onCancel: () => void;
  afterClose: () => void;
};

const { Option } = Select;
export default function AddNewTruck({
  showModal,
  onCancel,
  afterClose,
}: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [trucks, setTrucks] = useState<[]>([]);
  const [tonnage, setTonnage] = useState<[]>([]);
  const { dsp } = useAuth();

  useEffect(() => {
    axiosConfig
      .get("truck")
      .then(({ data: { data } }) => {
        setTrucks(data);
      })
      .catch((error) => {});
  }, []);

  async function handleSubmit(values: any) {
    setIsLoading(true);
    try {
      await axiosConfig.post("/truckmangement", {
        ...values,
        logisticsPartnerId: dsp?.id,
      });
      onCancel();
      form.resetFields();
      message.success("Truck created successfully");
    } catch (error) {
      message.error("Error creating truck" + error);
    } finally {
      setIsLoading(false);
    }
  }

  const SelectedTruck = (id: any) => {
    trucks.map(
      (truck: any) => truck.truckType === id && setTonnage(truck.truckTonnage)
    );
  };

  return (
    <Modal
      footer={null}
      visible={showModal}
      title={"Add new truck"}
      onCancel={onCancel}
      afterClose={afterClose}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row justify="space-around">
          <Col md={12}>
            <Form.Item
              rules={[
                { required: true, message: "Truck name cannot be blank" },
              ]}
              name="truckType"
              label="Truck Type"
            >
              <Select onChange={SelectedTruck}>
                {trucks &&
                  trucks.map((truck: any) => (
                    <Option key={truck.id} value={truck.truckType}>
                      {truck.truckType}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Brand cannot be blank" }]}
              name="brand"
              label="Brand"
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true, message: "Year cannot be blank" },
                {
                  type: "number",
                  min: 1900,
                  message: "Year cannot be earlier than 1900",
                },
              ]}
              name="year"
              label="Year"
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Color cannot be blank" }]}
              name="color"
              label="Color"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Registration number cannot be blank",
                },
              ]}
              name="regNumber"
              label="Registration number"
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Model cannot be blank" }]}
              name="model"
              label="Model"
            >
              <Input />
            </Form.Item>
            {
              <Form.Item
                rules={[{ required: true, message: "Tonnage cannot be blank" }]}
                name="tonnage"
                label="Tonnage"
              >
                <Select>
                  {tonnage &&
                    tonnage.map((tonne: any) => (
                      <Option key={tonne} value={tonne}>
                        {tonne}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            }

            <Form.Item
              rules={[{ required: true, message: "Vin cannot be blank" }]}
              name="vin"
              label="Vehicle ID number"
            >
              <Input minLength={15} />
            </Form.Item>
          </Col>
        </Row>
        <Button
          loading={isLoading}
          htmlType="submit"
          block
          type="primary"
          size="large"
        >
          Save
        </Button>
      </Form>
    </Modal>
  );
}
