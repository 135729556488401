import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { Form, FormItemProps, Input, FormInstance, AutoComplete } from "antd";
// import useAuth from "hooks/useAuth";
// import { debounce } from "lodash";
// import debounce from "lodash.debounce";

const SearchPlace = React.forwardRef(
  ({
    placeName,

    form,
    placeProps,
    placeGet,
    handleLOcation,
    handlePlaceId,
    handleSecondaryAddress,
    index,
    deliverySchedule,
    secondaryName,
    ...props
  }: FormItemProps & {
    placeName: string | (string | number)[];
    form: FormInstance;
    placeProps?: FormItemProps;
    placeGet?: string | (string | number)[];
    handleLOcation?: any;
    handlePlaceId?: any;
    handleSecondaryAddress?: any;
    index?: string | number;
    deliverySchedule?: boolean;
    secondaryName?: string | any;
  }) => {
    const [places, setPlaces] = useState<any[]>([]);

    // const [placeId, setPlaceId] = useState<string>("");
    // const [location, setLocation] = useState<string>("");
    const [secondaryAddress, setSecondaryAddress] = useState<boolean>(false);
    // const { builder } = useAuth();
    // const [value, setValue] = useState("");
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const handleSearchPlace = async (value: string) => {
      const { data } = await axios.post("searchplace", {
        location: value,
      });
      setPlaces(data.data);
      const optionSet: { value: string }[] = [];
      data.data.map((place: any) =>
        optionSet.push({ value: place.description })
      );
      setOptions(optionSet);
    };

    // setPlace_id(places.find((place) => place.description === value)?.place_id)}
    useEffect(() => {
      form.setFields([
        {
          name: props.name ?? "businessAddress",
          value: "",
        },
      ]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secondaryAddress]);

    const handleChange = (e: any) => {
      if (places.length < 1) {
        form.setFields([
          {
            name: secondaryName,
            value: e.target.value,
          },
        ]);
        setSecondaryAddress(true);
      }
    };
    const handleSearch = (val: string) => {
      const _placeId = places.find(
        (place) => place.description.toLowerCase() === val.toLowerCase()
      )?.place_id;

      const _location = places.find(
        (place) => place.description.toLowerCase() === val.toLowerCase()
      )?.description;

      if (places.length > 0) {
        form.setFields([
          {
            name: placeName,
            value: _placeId,
          },
        ]);
      }

      if (deliverySchedule && _location && _placeId) {
        handleLOcation(index, _location, "deliveryAddress");
        handlePlaceId(index, _placeId, "deliveryPlaceId");
      }
    };
    return (
      <>
        <Form.Item
          {...props}
          label={secondaryAddress ? "Enter closest bus stop" : props.label}
        >
          <AutoComplete
            showSearch
            onBlur={(e) => handleChange(e)}
            options={options}
            onSearch={handleSearchPlace}
            // onChange={handleChange}
            onSelect={handleSearch}
          />
          {/* <Select showSearch>
            {places.map((place) => (
              <Option key={place.place_id} value={place.description}>
                {place.description}
              </Option>
            ))}
          </Select> */}
        </Form.Item>
        <Form.Item {...placeProps} name={placeName} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          initialValue=""
          label="Secondary address"
          name={secondaryName}
          hidden={!secondaryAddress}
        >
          <Input
            onChange={(e) =>
              deliverySchedule
                ? handleSecondaryAddress(
                    index,
                    e.target.value,
                    "secondaryDeliveryAddress"
                  )
                : null
            }
          />
        </Form.Item>
      </>
    );
  }
);

export default SearchPlace;
