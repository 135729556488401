import {
  Button,
  Col,
  Empty,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
} from "antd";
import ItemCard from "components/Cards/ItemCard";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import DashboardShared from "components/DashboardShared/DashboardShared";
import styles from "./truck.module.css";
import { useContext, useEffect, useState } from "react";
import axiosConfig from "config/axios";
import AddNewTruck from "components/AddNewTruck";
import EditTruck from "components/EditTruck";
import SkeletonInput from "antd/lib/skeleton/Input";
import DeviceWidth from "context/MobileResizeContext";
import ErrorHandler from "utils/ErrorHandler";
import { Router } from "@reach/router";
import TruckDetails from "./truck-details";
import useAuth from "hooks/useAuth";
import useSearch from "hooks/useSearch";

interface TruckItemProp {
  title?: string;
  topRight?: string;
  subTitle?: string;
  id?: string;
  getTrucks: () => void;
  path?: string;
  available?: boolean;
  truck: truck[];
}

interface TruckMgt {
  trucks?: truck[];
  getTrucks: () => any;
  isLoading?: boolean;
  path?: string;
}
const TruckItem = (props: TruckItemProp) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const handleDelete = (e: any) => {
    setIsDeleting(true);
    e.stopPropagation();
    axiosConfig
      .delete(`truckmangement/delete/${props.id}`)
      .then(() => {
        message.success(`${props.subTitle} deleted successfully`);
        props.getTrucks && props.getTrucks();
      })
      .catch((error) => ErrorHandler(error))
      .finally(() => setIsDeleting(false));
  };
  return (
    <div className={`${props.available ? "" : styles.unavailable}`}>
      <ItemCard
        isTruck
        {...props}
        bottomRight={
          <Space onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              okButtonProps={{ danger: true, size: "middle" }}
              cancelButtonProps={{ size: "middle" }}
              title="are you sure you want to delete this truck?"
              onConfirm={handleDelete}
            >
              <Button
                loading={isDeleting}
                onClick={(e) => e.stopPropagation()}
                type="link"
                danger
              >
                Delete
              </Button>
            </Popconfirm>
            <Button
              style={{ padding: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                setShowModal(true);
              }}
              type="link"
            >
              Edit
            </Button>
          </Space>
        }
      />
      <EditTruck
        id={props.id}
        truck={props.truck}
        afterClose={() => props.getTrucks && props.getTrucks()}
        showModal={showModal}
        onCancel={() => setShowModal(false)}
      />
    </div>
  );
};

const TruckManagement = ({ trucks, isLoading, getTrucks }: TruckMgt) => {
  const isMobile = useContext(DeviceWidth);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { handleSearch, isSearching, searchQuery } = useSearch();

  let searchResult = trucks?.filter((truck) => {
    return (
      truck.regNumber.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      truck.truckType.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      truck.model.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const trueData = isSearching ? searchResult : trucks;
  return (
    <div>
      <div className={styles.container}>
        <Row justify="end" className={styles["body-topbar"]}>
          <Input
            onChange={(e) => handleSearch(e)}
            style={{ width: "300px" }}
            prefix={<SearchOutlined />}
          />
          <Button
            onClick={() => setShowModal(true)}
            type="primary"
            icon={<PlusOutlined />}
          >
            {" "}
            Add new truck
          </Button>
        </Row>
        <Row
          gutter={[10, 10]}
          justify="center"
          className={styles["truck-items"]}
        >
          {isLoading && !trucks
            ? [1, 2, 3].map((i) => (
                <Col xs={24} sm={24} md={12} lg={8}>
                  <SkeletonInput
                    key={i}
                    style={{
                      width: `${isMobile ? "350px" : "300px"}`,
                      height: "100px",
                      borderRadius: "5px",
                    }}
                    active={true}
                    size={`large`}
                  />
                </Col>
              ))
            : trucks &&
              trucks.length < 1 && <Empty description="No truck added yet" />}

          {/* <TruckItem
            title={`regNumber`}
            subTitle={`truckType`}
            topRight={`model`}
            id={`id`}
            getTrucks={getTrucks}
          /> */}
          {trucks &&
            trueData?.map((truck: any, idx: any) => (
              <Col key={idx} xs={24} sm={24} md={12} lg={8}>
                <TruckItem
                  truck={truck}
                  title={truck.regNumber}
                  subTitle={truck.truckType}
                  topRight={truck.model}
                  id={truck.id}
                  getTrucks={() => getTrucks()}
                  available={truck.available}
                />
              </Col>
            ))}
        </Row>
      </div>
      <AddNewTruck
        afterClose={() => getTrucks && getTrucks()}
        showModal={showModal}
        onCancel={() => setShowModal(false)}
      />
    </div>
  );
};

const MainView = () => {
  const { dsp } = useAuth() as { dsp: DSP };
  const [trucks, setTrucks] = useState<truck[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    Promise.all([getTrucks()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTrucks = () => {
    setIsLoading(true);
    axiosConfig
      .get(`truckmangement/${dsp.id}`)
      .then(({ data: { data } }) => {
        setIsLoading(false);
        setTrucks(data);
      })
      .catch((error) => {
        setIsLoading(false);
        ErrorHandler(error, "An error occured");
      });
  };
  return (
    <DashboardShared title="Truck Management">
      <Router>
        <TruckManagement
          isLoading={isLoading}
          getTrucks={() => getTrucks()}
          trucks={trucks}
          path="/"
        />
        <TruckDetails
          dspId={dsp.id}
          getTrucks={getTrucks}
          trucks={trucks}
          path="/:id"
        />
      </Router>
    </DashboardShared>
  );
};

export default MainView;
