import { Button, Checkbox, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import styles from "../profile/profile.module.css";
import axios from "config/axios";
import CreateBankModal from "components/CreateBankModal/CreateBankModal";
import useAuth from "hooks/useAuth";
import PageSpinner from "components/PageSpinner";

export default function Bank(props: ReachPathProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accounts, setAccounts] = useState<BankAccount[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>();
  const { user } = useAuth() as { user: User };
  const { organizationId } = user;

  const getBankAccounts = useCallback(
    async function getBankAccounts() {
      setIsLoading(true);
      try {
        const {
          data: {
            data: { accounts },
          },
        } = await axios.get(`account/${organizationId}`);
        setAccounts(accounts);
        setIsLoading(false);
      } catch (error) {}
    },
    [organizationId]
  );

  useEffect(() => {
    getBankAccounts();
  }, [getBankAccounts]);
  return (
    <>
      <div>
        <div className="c-end">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Add new bank account
          </Button>
        </div>
        {!accounts || isLoading ? (
          <PageSpinner page />
        ) : (
          accounts.map((account) => (
            <Accounts
              key={account.accountNumber}
              {...account}
              getBankAccounts={getBankAccounts}
              organizationId={organizationId}
            />
          ))
        )}
        {accounts && !isLoading && accounts.length < 1 && (
          <Empty description="No Bank Accounts created" />
        )}

        <CreateBankModal
          isModalOpen={isModalOpen}
          onCancel={() => {
            setIsModalOpen(false);
          }}
          getBankAccounts={getBankAccounts}
        />
      </div>
    </>
  );
}

function Accounts(
  props: BankAccount & {
    getBankAccounts: () => Promise<void>;
    organizationId: string;
  }
) {
  return (
    <>
      <div className={styles.table}>
        <table>
          <tbody>
            <tr>
              <th>Bank name</th>
              <td>{props.bankName}</td>
            </tr>
            <tr>
              <th>Account name</th>
              <td>{props.accountName}</td>
            </tr>
            <tr>
              <th>Account number</th>
              <td>{props.accountNumber}</td>
            </tr>
            <tr>
              <th>Set as default</th>
              <td>
                <Checkbox
                  checked={props.isDefault}
                  onChange={async (e) => {
                    if (e.target.checked) {
                      await axios.put(`account/${props.id}/default`, {
                        organizationId: props.organizationId,
                      });
                      props.getBankAccounts();
                    }
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
