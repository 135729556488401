import styles from "./cards.module.css";
import { Typography } from "antd";
import { navigate } from "@reach/router";

const { Text, Title } = Typography;
interface itemProps {
  image?: string;
  title?: string | JSX.Element;
  subTitle?: string | JSX.Element;
  topRight?: string;
  bottomRight?: string | JSX.Element;
  id?: string;
  isTruck?: boolean;
}
const ItemCard = ({
  image,
  title,
  subTitle,
  topRight,
  bottomRight,
  id,
  isTruck,
}: itemProps) => {
  return (
    <div
      onClick={(e) => {
        isTruck && navigate(`./truck-management/${id}`);
      }}
      className={styles.item}
    >
      {image && (
        <div className={styles["item-img"]}>
          <img src={image} alt="item" width="50" height="50" />
        </div>
      )}
      <div className={styles["item-text"]}>
        <div className={styles["item-main-text"]}>
          <Title level={4}>{title}</Title>
          <Text
            style={{ marginLeft: "2px", display: "flex", fontSize: "1rem" }}
          >
            {topRight}
          </Text>
        </div>
        <div className={styles["item-footer"]}>
          <Text
            className={styles["sub-title"]}
            style={{ width: "85%", fontSize: "1rem" }}
          >
            {subTitle}
          </Text>
          <Text style={{ fontSize: "1rem" }}>{bottomRight}</Text>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
