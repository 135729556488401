import { UploadRequestOption } from "rc-upload/lib/interface";
import { uploadFile } from "react-s3";

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

export default async function uploadToS3({ file, onSuccess, onError }: UploadRequestOption) {
  try {
    const data = await uploadFile(file, config);
    onSuccess?.(
      data.location,
      //@ts-ignore
      file
    );
    return data.location;
  } catch (error) {
    //@ts-ignore
    onError?.(error);
  }
}
