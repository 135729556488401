import { Menu } from "react-feather";
import { useDispatch } from "react-redux";
import Bell from "../../assets/icons/Bell";
import { toggle } from "../../redux/slices/sidenav";
import styles from "./dashshared.module.css";

type DashboardSharedProps = {
  title?: string | any;
  children: React.ReactNode;
};
export default function DashboardShared(props: DashboardSharedProps) {
  const dispatch = useDispatch();
  return (
    <div className={styles.main}>
      <div className={styles.topbar}>
        <h1>{props.title} &nbsp;</h1>
        <button className={styles.bell}>
          <Bell />
        </button>
        <button
          className={styles.toggle}
          onClick={() => {
            dispatch(toggle());
          }}
        >
          <Menu />
        </button>
      </div>
      <div className={styles.content}>
        <div>{props.children}</div>
      </div>
    </div>
  );
}
