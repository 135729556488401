import { navigate } from "@reach/router";
import { Button, message, Switch } from "antd";
import axiosConfig from "config/axios";
import styles from "./truck.module.css";

interface TDprops {
  trucks?: truck[];
  path?: string;
  id?: string;
  getTrucks?: () => any;
  isLoading?: boolean;
  dspId?: string;
}

const TruckDetails = ({ trucks, id, getTrucks, dspId }: TDprops) => {
  // const {dsp} = useAuth()
  const handleAvailability = () => {
    axiosConfig
      .put(`truckmangement/toggle/${id}`, { logisticsPartnerId: dspId })
      .then((res) => {
        getTrucks && getTrucks();
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  return (
    <div className={styles.container}>
      {trucks &&
        trucks.map(
          (truck, idx) =>
            truck.id === id && (
              <>
                <div className={styles.header}>
                  <span>
                    <Button
                      style={{ padding: 0 }}
                      type="text"
                      onClick={() => navigate("/dsp/truck-management")}
                    >
                      {" "}
                      Trucks
                    </Button>{" "}
                    |{" "}
                    <i style={{ opacity: 0.5 }}>{id?.split("").splice(0, 6)}</i>
                  </span>
                  <div className="availability">
                    <b>Set truck availabilty</b>
                    <div className="set-availability">
                      <Switch
                        checked={truck.available}
                        checkedChildren={<>ON</>}
                        unCheckedChildren={<>OFF</>}
                        // disabled={isLoading}
                        onChange={() => handleAvailability()}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles["details-content"]}>
                  <div
                    className={`${styles["details-card"]} ${
                      truck.available ? "" : styles.unavailable
                    }`}
                  >
                    <>
                      <span>
                        Truck model
                        <b>{truck.model}</b>
                      </span>
                      <span>
                        Truck brand
                        <b>{truck.brand}</b>
                      </span>
                      <span>
                        Truck type
                        <b>{truck.truckType}</b>
                      </span>
                      <span>
                        Registration Number
                        <b>{truck.regNumber}</b>
                      </span>
                      <span>
                        Vehicle Identification Number
                        <b>{truck.vin}</b>
                      </span>
                      <span>
                        Color
                        <b>{truck.color}</b>
                      </span>
                      <span>
                        Truck Tonnage
                        <b>{truck.tonnage}</b>
                      </span>
                      <span>
                        Year
                        <b>{truck.year}</b>
                      </span>
                    </>
                  </div>
                </div>
              </>
            )
        )}
    </div>
  );
};

export default TruckDetails;
