import styles from "./profile.module.css";
import { Button } from "antd";
import { Edit } from "react-feather";
import { useState } from "react";
import useAuth from "hooks/useAuth";
import EditBusinessInfo from "components/EditBusinessInfo";
// import EditProfileModal from "../../../components/EditProfileModal/EditProfileModal";

export default function Business(props: ReachPathProps) {
  const { dsp } = useAuth();
  const [isEditOpen, setIsEditOpen] = useState(false);

  return (
    <>
      <div className={styles.main}>
        <div className={styles.heading}>
          <Button
            type="link"
            icon={<Edit height={14} />}
            onClick={() => {
              setIsEditOpen(true);
            }}
          >
            Edit business info
          </Button>
        </div>
        <div>
          <div className={styles.table}>
            <table>
              <tr>
                <th>Business name</th>
                <td>{dsp!.businessName}</td>
              </tr>
              <tr>
                <th>Business address</th>
                <td>{dsp!.businessAddress}</td>
              </tr>
              <tr>
                <th>Zip/Postal code</th>
                <td>{dsp!.zipCode}</td>
              </tr>
              <tr>
                <th>State/Country</th>
                <td>{dsp!.state}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <EditBusinessInfo
        isEditOpen={isEditOpen}
        onCancel={() => {
          setIsEditOpen(false);
        }}
      />
    </>
  );
}
