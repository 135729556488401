import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const locCart = localStorage.getItem("mp-cart");

const initialState = locCart ? (JSON.parse(locCart) as { cart: CartItem[] }) : ({ cart: [] } as { cart: CartItem[] });

const slice = createSlice({
  initialState,
  name: "cart",
  reducers: {
    addToCart(state, action: PayloadAction<CartItem>) {
      state.cart.push(action.payload);
    },
    removeFromCart(state, action: PayloadAction<{ i: number; j: number }>) {
      delete state.cart[action.payload.i].details[action.payload.j];
    },
    editItemQuantity(state, action: PayloadAction<{ i: number; j: number; quantity: string }>) {
      if (state.cart[action.payload.i]) {
        state.cart[action.payload.i].details[action.payload.j].amount = action.payload.quantity;
      }
    },
  },
});

export default slice.reducer;
export const { addToCart, editItemQuantity, removeFromCart } = slice.actions;
