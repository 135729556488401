import { Link } from "@reach/router";
import styles from "./toptab.module.css";

export default function TopTab({
  links,
}: {
  links: { to: string; name: string }[];
}) {
  return (
    <div style={{ height: "auto" }}>
      <div className={styles.tab}>
        {links.map(({ to, name }) => (
          <Link
            getProps={(props) => {
              return props.isCurrent
                ? {
                    style: {
                      background: "#00962C",
                      color: "#fff",
                      boxShadow: "0px 2px 10px #00962C77",
                    },
                  }
                : {};
            }}
            to={to}
            key={name}
          >
            {name}
          </Link>
        ))}
      </div>
    </div>
  );
}
