import { Router } from "@reach/router";
import Home from "pages/Home";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import "./App.less";
import "./global.css";
import "animate.css/animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { MobileResizeContext } from "context/MobileResizeContext";
import DSP from "pages/dsp";
import useAuth from "hooks/useAuth";
import axios from "config/axios";
import NotFound from "pages/404";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ForgotPassword/reset";

export default function App() {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 2000,
      once: true,
    });
  }, []);
  const [isValidating, setIsValidating] = useState(true);
  const { logIn, updateToken, logOut } = useAuth();
  useEffect(() => {
    const token = localStorage.getItem("token-dsp-v1");
    (async function () {
      try {
        if (token) {
          updateToken(token);
          const {
            data: { data: user },
          } = (await axios.get("user")) as { data: { data: User } };

          const {
            data: { data: logisticsPartner },
          } = await axios.get(`logistics/getonepartner/${user.organizationId}`);
          logIn(user, logisticsPartner[0].logisticsPartner[0]);
        }
      } catch (error) {
        logOut();
      } finally {
        setIsValidating(false);
      }
    })();
  }, [logIn, logOut, updateToken]);
  if (isValidating) return <></>;
  return (
    <MobileResizeContext>
      <Router>
        <Home path="/" />
        <SignUp path="/signup" />
        <SignIn path="/login" />
        <DSP path="/dsp/*" />
        <ForgotPassword path="forgot-password" />
        <ResetPassword path="forgot-password/reset" />
        <NotFound path="*" />
      </Router>
    </MobileResizeContext>
  );
}
