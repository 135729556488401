import { Button, Col, Empty, Input, Row } from "antd";
import ItemCard from "components/Cards/ItemCard";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./orders.module.css";
import { useCallback, useEffect, useState } from "react";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import { navigate } from "@reach/router";
import PageSpinner from "components/PageSpinner";
import useSearch from "hooks/useSearch";
// import axiosConfig from "config/axios";

const Active = () => {
  const { dsp } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [data, setData] = useState<Transaction[]>([]);

  const { handleSearch, isSearching, searchQuery } = useSearch();

  let searchResult = data.filter((d) => {
    return (
      d.builder?.businessName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      d?.frontendId.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      d?.order.frontendId.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  });
  // const [dspOrder, setDspOrder] = useState<boolean>();
  const getDeliveries = useCallback(async () => {
    setIsLoading(true);
    const {
      data: { data },
    } = await axios.get(`logistics/getpendingdeliveriesdsp/${dsp?.id}`);
    setData(data);
    setIsLoading(false);
  }, [dsp?.id]);

  useEffect(() => {
    getDeliveries();
  }, [getDeliveries]);
  // const getPending = (transaction: any) => {
  //   switch (transaction.trackingStatus) {
  //     case "pending":
  //     case "order_processed":
  //       return true;
  //   }
  // };

  const trueData = isSearching ? searchResult : data;
  return (
    <div className={styles["active-container"]}>
      <Row justify="end" className={styles["body-topbar"]}>
        <Input
          onChange={(e) => handleSearch(e)}
          onFocus={(e) => handleSearch(e)}
          style={{ width: "300px" }}
          prefix={<SearchOutlined />}
        />
      </Row>
      {!data || isLoading ? (
        <PageSpinner page />
      ) : (
        <Row gutter={[30, 30]} justify="start">
          {trueData.map((datum) => (
            <Col
              onClick={() => {
                navigate(`./delivery/${datum.id}`);
              }}
              style={{ position: "relative" }}
              xs={24}
              sm={24}
              md={12}
            >
              {/* {getOrderInfo(datum.id) && (
                <div>
                  <InfoCircleFilled
                    style={{
                      color: "#00962C",
                      position: "absolute",
                      top: "5%",
                      right: "5%",
                    }}
                  />
                </div> 
              )}*/}
              <ItemCard
                title={datum.builder?.businessName ?? datum.builderName}
                topRight={`₦${datum.deliveryPrice?.toLocaleString()}`}
                subTitle={
                  <div className={styles.ids}>
                    <h5>
                      Transaction ID: <span>{datum.frontendId}</span>
                    </h5>
                    <h5>
                      Invoice ID: <span>{datum.order.frontendId}</span>
                    </h5>
                  </div>
                }
                bottomRight={
                  <Button type="link" onClick={() => navigate(`./${datum.id}`)}>
                    detail
                  </Button>
                }
              />
            </Col>
          ))}
        </Row>
      )}
      {data && !isLoading && data.length < 1 && (
        <Empty description="No Orders yet" />
      )}
    </div>
  );
};

export default Active;
