import { Bell as BellIcon } from "react-feather";

const Bell = () => {
  return (
    <div
      style={{
        padding: ".6rem 1rem",
        borderRadius: "10px",
        backgroundColor: "#00962c1a",
      }}
    >
      <BellIcon />
    </div>
  );
};

export default Bell;
