import { Table, Button, Input, Space, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import styles from "./orders.module.css";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import DashboardShared from "components/DashboardShared/DashboardShared";
import { navigate, Router } from "@reach/router";
import OrderDetails from "./order-details";
import TopTab from "components/TopTab/TopTab";
import Active from "./active";
import useAuth from "hooks/useAuth";
import { useCallback, useEffect, useState } from "react";
import axios from "config/axios";
import dayjs from "dayjs";
import useSearch from "hooks/useSearch";

const AllOrders = (props: ReachPathProps) => {
  const { dsp } = useAuth();
  const [data, setData] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>();

  const { handleSearch, isSearching, searchQuery } = useSearch();

  let searchResult = data.filter((d) => {
    return d.id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
  });
  const getDeliveries = useCallback(async () => {
    setLoading(true);
    const {
      data: { data },
    } = await axios.get(`logistics/getfufilleddeliveriesdsp/${dsp?.id}`);
    setData(data);
    setLoading(false);
  }, [dsp?.id]);
  useEffect(() => {
    getDeliveries();
  }, [getDeliveries]);
  const webTableColumns = [
    {
      title: "INVOICE ID",
      dataIndex: "order",
      key: "order",
      render: (text: any) => text?.frontendId,
    },
    {
      title: "DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) =>
        dayjs(text).isValid() ? dayjs(text).format("DD MMM YYYY") : "",
    },
    {
      title: "CREATED BY",
      dataIndex: "builder",
      key: "builder",
      ellipsis: true,
      render: (text: DSP) => (
        <Tooltip title={text?.businessName}>{text?.businessName}</Tooltip>
      ),
    },
    // {
    //   title: "EXP. DELIVERYDATE",
    //   dataIndex: "expires",
    //   key: "expires",
    // },
    {
      title: "TRACKING STATUS",
      dataIndex: "trackingStatus",
      key: "trackingStatus",
      render: (text: string) => (
        <div className={styles.total}>
          <Text>{text.replace(/_/g, " ")}</Text>
        </div>
      ),
    },
    {
      title: "DELIVERY CHARGE",
      dataIndex: "deliveryPrice",
      key: "deliveryPrice",
      render: (text: string) => (
        <div className={styles.total}>
          {text}
          <Button type="text" className={styles["more-button"]}>
            <MoreOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <TopTab
        links={[
          { to: "../", name: "Active" },
          { to: "", name: "History" },
        ]}
      />
      <div className={`${styles.container} ${styles.grey}`}>
        <div className={styles["body-topbar"]}>
          <Text>Delivery orders</Text>
          <Space>
            <Input
              onChange={(e) => handleSearch(e)}
              prefix={<SearchOutlined />}
            />
          </Space>
        </div>
        <div style={{ overflowY: "auto" }}>
          <Table
            pagination={false}
            columns={webTableColumns}
            dataSource={isSearching ? searchResult : data}
            loading={loading}
            className={styles.table}
            rowClassName={styles["table-row"]}
            style={{ minWidth: "600px", textTransform: "capitalize" }}
            onRow={(row) => {
              return {
                onClick: (event) => {
                  navigate(`${row.id}`);
                },
              };
            }}
          />
        </div>
      </div>
    </>
  );
};

const DeliveryOrdersView = (props: ReachPathProps) => {
  return (
    <>
      <TopTab
        links={[
          { to: "", name: "Active" },
          { to: "completed", name: "History" },
        ]}
      />
      <Active />
    </>
  );
};

export default function DeliveryOrders() {
  return (
    <DashboardShared title="Delivery orders">
      <Router>
        <DeliveryOrdersView path="/" />
        <AllOrders path="/completed" />
        <OrderDetails path="/:id" />
      </Router>
    </DashboardShared>
  );
}
