import { Redirect, Router } from "@reach/router";
import SideNav from "components/SideNav/SideNav";
import dashboardRoute from "config/dashboardRoute";
import useAuth from "hooks/useAuth";
import styles from "./layout.module.css";

export default function Builder(props: ReachPathProps) {
  const { user } = useAuth();

  if (!user) return <Redirect from="*" to="/login" noThrow />;
  return (
    <div className={styles.layout}>
      <SideNav />
      <main className={styles.main__container}>
        <Router>
          {dashboardRoute.map((route) => (
            <route.component path={route.path} key={route.path} />
          ))}
        </Router>
      </main>
    </div>
  );
}
