import AuthFormShared, {
  AuthButton,
} from "components/AuthFormShared/AuthFormShared";
import {
  Form,
  Input,
  Row,
  Col,
  InputNumber,
  Select,
  Upload,
  Button,
  Spin,
  message,
} from "antd";
// import { PhoneInput, PhoneInputFormItem } from "@validate-phone/antd-input";
import { useEffect, useState } from "react";
import { Link, Redirect, useLocation } from "@reach/router";
import states from "data/states";
import axios from "axios";
import { ArrowUpOutlined, RollbackOutlined } from "@ant-design/icons";
import SearchPlace from "components/SearchPlace";
import uploadToS3 from "utils/uploadToS3";
import ErrorHandler from "utils/ErrorHandler";
import styles from "./signup.module.css";
import { parse } from "fast-qs";
import axiosConfig from "config/axios";

const { Option } = Select;

export default function SignUp(props: ReachPathProps) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [localGovernment, setLocalGovernmanet] = useState<any>([]);
  const [selectedLocalGovernment, setSelectedLocalGovernmanet] = useState<any>(
    []
  );

  const { search } = useLocation();

  const [verified, setVerified] = useState<boolean>();
  const { email, token } = parse(search) as { email: string; token: string };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/verifytoken`, {
        email,
        token,
      })
      .then((data) => {
        setVerified(true);
      })
      .catch((error) => {
        message.error("Token not verified!");
        setVerified(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit(values: any) {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/logistics/register`,
        {
          ...values,
          businessLogo:
            values.businessLogo ??
            "https://materialspro-media.s3.us-west-1.amazonaws.com/user-avatar.png",
        }
      );
      setIsSubmitted(true);
    } catch (error: any) {
      ErrorHandler(error, "An Error Occured");
    } finally {
      setIsLoading(false);
    }
  }

  const getLGA = async () => {
    await axiosConfig
      .get("nigerianstate")
      .then((res) => {
        setLocalGovernmanet(res.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getLGA();
  }, []);

  const handleChange = (e: any) => {
    const ordered = localGovernment.filter((lga: any) => e === lga.state)[0]
      .Lgas;
    setSelectedLocalGovernmanet(ordered);
  };

  if (verified === undefined)
    return (
      <div className={styles["confirm-email-wrapper"]}>
        <Spin />
      </div>
    );
  if (verified === false) {
    return <Redirect from="*" to="../404" />;
  }

  if (isSubmitted)
    return (
      <div className={styles["confirm-email-wrapper"]}>
        <div className={styles["email-confirm-variant"]}>
          <div className={styles["email-circle"]} />
          <div className={styles["email-circle2"]} />
          <img src="/email-confirm2.png" alt="email-confirm" width={250} />
          <p>
            Account created successfully!
            <span>
              An email will be sent once your organization has been approved
            </span>
          </p>
          <div>
            <Button
              icon={<RollbackOutlined />}
              size="large"
              type="primary"
              className={styles.blink}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              href={`${process.env.REACT_APP_HOME_URL}/blog`}
            >
              Visit Blog
            </Button>
          </div>
        </div>
      </div>
    );

  return (
    <AuthFormShared title="Register As a DSP" {...props}>
      <div className="w-100">
        <Form
          scrollToFirstError
          // eslint-disable-next-line no-template-curly-in-string
          validateMessages={{ required: "label is required!" }}
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="businessName"
            label="Business Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="adminFirstName"
                label="Admin's First Name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="adminLastName"
                label="Admin's Last Name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col xs={24} md={12}>
              {/* <PhoneInputFormItem
                name="adminPhoneNumber"
                label="Phone number"
                errorMessage="Please provide a valid phone number"
                hasFeedback
                countryCode="NG">
                <PhoneInput />
              </PhoneInputFormItem> */}
              <Form.Item
                name="adminPhoneNumber"
                label="Phone Number"
                rules={[
                  { required: true },
                  {
                    pattern: /^(\+?234?)?0?(7|8|9)(0|1)\d{8}$/,
                    message: "Please provide a valid phone number.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="adminEmail"
                label="Email Address"
                initialValue={email}
                rules={[
                  { required: true },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <SearchPlace
            form={form}
            name="businessAddress"
            label="Business Address"
            secondaryName="secondaryBusinessAddress"
            rules={[{ required: true }]}
            placeName="businessAddressPlaceId"
          />
          <Form.Item
            name="businessLogo"
            label="Upload Business Logo"
            valuePropName="file"
            getValueFromEvent={(e) => {
              const file = e.fileList[0];
              if (file?.status === "done") {
                return file.response;
              }
            }}
          >
            <Upload
              customRequest={uploadToS3}
              listType="picture"
              multiple={false}
              maxCount={1}
              accept="image/*"
            >
              <Button icon={<ArrowUpOutlined />}>
                Click Here to upload an image
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Please enter a Tax ID" }]}
            name="taxId"
            label="Enter tax ID"
          >
            <Input />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Please upload CAC Document" }]}
            name="document"
            label="Upload CAC Document"
            valuePropName="file"
            getValueFromEvent={(e) => {
              const file = e.fileList[0];
              if (file?.status === "done") {
                return file.response;
              }
            }}
          >
            <Upload
              customRequest={uploadToS3}
              listType="picture"
              multiple={false}
              maxCount={1}
              accept="image/*,.pdf,.doc"
            >
              <Button icon={<ArrowUpOutlined />}>
                Click Here to upload CAC document
              </Button>
            </Upload>
          </Form.Item>

          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="state"
                label="State"
                rules={[{ required: true }]}
              >
                <Select onChange={(e) => handleChange(e)}>
                  {states.map((state) => (
                    <Option
                      value={
                        state === "FCT Abuja"
                          ? "Federal Capital Territory"
                          : state
                      }
                      key={state}
                    >
                      {state}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {selectedLocalGovernment.length > 0 && (
              <Col xs={24} md={12}>
                <Form.Item
                  name="localGovernment"
                  label="LGA"
                  rules={[{ required: true }]}
                >
                  <Select>
                    {selectedLocalGovernment.map((lgas: any) => (
                      <Option value={lgas} key={lgas}>
                        {lgas}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Col xs={24} md={12}>
            <Form.Item
              name="zipCode"
              label="Zip Code"
              rules={[{ type: "integer" }]}
            >
              <InputNumber minLength={3} className="w-100" maxLength={6} />
            </Form.Item>
          </Col>

          <Form.Item
            label="Password"
            name="adminPassword"
            rules={[
              { required: true, message: "Please input your password!" },
              { min: 6 },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <AuthButton isLoading={isLoading}>Register</AuthButton>
        </Form>
        <p className="text-center">
          Already have an account? <Link to="/login">Sign in</Link>
        </p>
      </div>
    </AuthFormShared>
  );
}
